<template>
    <div class="stepBox">
        <div class="stepContent">
            <div v-for="(item, index) in stepList" :key="index" class="stepList">
                <div class="step" @click="jump(index)">
                    <div
                        v-show="!item.show"
                        style="margin-right:13px"
                        :class="stepNum == index ? 'blue-circle' : 'gray-circle'"
                        >{{ item.num }}</div>
                        <div v-show="item.show">
                            <i class="el-icon-circle-check" style="color:#156ED0;font-size:26px;margin-right:14px"></i>
                        </div>
                    <div style="margin-right:9px;font-size:14px;width:100px;line-height: 24px;">
                        <div :style="index == stepNum?'color:#156ED0':index > stepNum?'color:rgba(0, 0, 0, 0.4)':'color:#000'">{{ item.title }}</div>
                    </div>
                    <div
                        v-show="index < 3"
                        :style="index == stepNum?'width:130px;height:2px;background-color: #DCDCDC;margin-top:10px':index >stepNum?'width:130px;height:2px;background-color: #DCDCDC;margin-top:10px':'width:130px;height:2px;background-color: #156ED0;margin-top:10px'"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      stepList: [
        { num: 1, title: '输入企业名称', active: true, show: true },
        { num: 2, title: '完善基础信息', active: false, show: true },
        { num: 3, title: '勾画企业画像', active: false, show: true },
        { num: 4, title: '匹配结果', active: false, show: true }
      ]
    };
  },
  props: {
    stepNum: Number
  },
  mounted () {
    this.stepList.forEach((item, index) => {
      if (this.stepNum === 0) {
        this.stepList[0].show = false;
        this.stepList[1].show = false;
        this.stepList[2].show = false;
        this.stepList[3].show = false;
      } else if (this.stepNum === 1) {
        this.stepList[0].show = true;
        this.stepList[1].show = false;
        this.stepList[2].show = false;
        this.stepList[3].show = false;
      } else if (this.stepNum === 2) {
        this.stepList[0].show = true;
        this.stepList[1].show = true;
        this.stepList[2].show = false;
        this.stepList[3].show = false;
      } else {
        this.stepList[0].show = true;
        this.stepList[1].show = true;
        this.stepList[2].show = true;
        this.stepList[3].show = false;
      }
    });
    this.stepList.forEach((v, i) => { i === this.stepNum ? v.active = true : v.active = false; });
  },
  methods: {
    jump (index) {
      if (index === 0 && this.stepList[index].show) {
        localStorage.removeItem('localData');
        this.$router.push({
          path: 'searchCompany'
        });
      } else if (index === 1 && this.stepList[index].show) {
        const params = localStorage.getItem('paramsData');
        localStorage.removeItem('localData');
        this.$router.push({
          path: 'infoCompany',
          query: {
            entityName: JSON.parse(params).entityName,
            navigateTo: '1'
          }
        });
      } else if (index === 2 && this.stepList[index].show) {
        localStorage.removeItem('localData');
        this.$router.push({
          path: 'chooseCompany',
          query: { entityName: JSON.parse(localStorage.getItem('paramsData')).entityName }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.stepBox{
    // width: 90%;

}
.stepContent {
    display: flex;
    margin-bottom: 40px;
    background: #fff;
    padding:0px  40px 0px 40px;

    .stepList {
        display: flex;
        margin-right: 9px;

        .step {
            display: flex;
        }
    }
}

.blue-circle {
    width: 26px;
    height: 26px;
    background: #156ED0;
    border-radius: 50%;
    line-height: 26px;
    text-align: center;
    color: #fff;
    font-size: 14px;
}

.gray-circle {
    width: 24px;
    height: 24px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    line-height: 24px;
    text-align: center;
    font-size: 14px;
}
</style>
