<template>
  <div class="infoCompany">
    <step :stepNum="stepNum"></step>
    <div class="companyName">
      <div>
        <img src="~@/assets/pc/images/matching/qiye.png" />
      </div>
      <div>{{ companyName }}</div>
      <div>请先完善您企业的</div>
      <div>基础信息</div>
    </div>
    <div class="infoContent">
      <!-- 行政区域 -->
      <div class="region">
        <div class="mandatory">行政区域</div>
        <div class="region-select">
          <div v-for="(item, index) in regionList" :key="index">
            <el-select v-model="item.code" :placeholder="
              index == '0'
                ? '请选择省'
                : index == '1'
                  ? '请选择市'
                  : index == '2'
                    ? '请选择区'
                    : '请选择镇街'
            " @change="changeRegion(index, item.code)">
              <el-option v-for="v in item.options" :key="v.code" :label="v.name" :value="v.code">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <!-- 企业规模 -->
      <div class="companyScale">
        <div class="companyScale-title">
          <div class="mandatory">企业规模</div>
          <div @click="handleStandard">
            <img src="~@/assets/pc/images/matching/question.png" />
            <span>参考标准</span>
          </div>
        </div>
        <div class="companyScale-radioGroup">
          <el-radio-group v-model="scaleRadio">
            <el-radio v-for="item in enterpriseGroup" :key="item.tagId" :label="item.tagId">{{ item.tagName }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>

      <!-- 高新技术产业 -->
      <div class="technology">
        <div class="mandatory">高新技术企业</div>
        <div class="technology-radioGroup">
          <el-radio-group v-model="technologyRadio">
            <el-radio v-for="item in technologyGroup" :key="item.id" :label="item.id">{{ item.name }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>

      <!-- 所属行业 -->
      <div class="industry">
        <div class="mandatory">所属行业</div>
        <div class="industry-checkGroup">
          <div v-for="(item, index) in industryList" :key="index" class="industry-checkGroup-items"
            @mouseenter="mouseenter(index)" @mouseleave="mouseLeave(index)">
            <el-checkbox v-model="item.isChecked" style="height:30px;"
              @change="changeIndustryGroup(index, item.isChecked)">{{ item.tagName }}</el-checkbox>
            <span v-if="item.isFirst" class="preferred">首选</span>
            <div style="flex:1">
              <span @click="setPreferred(index)" :class="showIndex == index ? 'prepare' : 'no-prepare'">{{ showIndex ==
                  index ? '首选' : ''
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 行业大类 -->
      <div class="category">
        <!-- <div class="no-mandatory">行业大类</div> -->
        <div class="catgory-checkGroup">
          <div v-for="(item, index) in industryList" :key="index" style="margin-bottom: 40px">
            <div style="color: rgba(0, 0, 0, 0.8); font-weight: bold; margin-bottom: 20px" v-if="item.isChecked">
              {{ item.tagName }}
            </div>
            <el-checkbox-group v-model="checkListCategory[index]">
              <el-checkbox v-for="(v, i) in item.bigCategory" :key="i" :label="v.tagId" :checked="v.isChecked"
                style="margin-bottom: 20px; width: 30%" @change="handleCheckListCategory(index, i, v)">{{ v.tagName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align:center">
      <el-button round style="width:143px;height:43px;margin-top:38px;background: #156ED0;color:#FFF"
        @click="submit" :loading="btnLoading">{{btnTitle}}</el-button>
    </div>
    <div style="text-align:center">
      <el-button round style="width:143px;height:43px;margin-top:16px" @click="goBack">返回</el-button>
    </div>
  </div>
</template>

<script>
import step from '../components/index.vue';
import { getMemberId } from '@/assets/public/utils/token';
import { request } from '@/network';
export default {
  components: {
    step
  },
  data () {
    return {
      companyName: '',
      stepNum: 1,
      regionList: [
        { code: '', name: '', options: [] },
        { code: '', name: '', options: [] },
        { code: '', name: '', options: [] },
        { code: '', name: '', options: [] }
      ],
      scaleRadio: '0',
      enterpriseGroup: [],
      technologyRadio: '1',
      technologyGroup: [
        { id: '1', name: '是' },
        { id: '0', name: '否' }
      ],
      checkListIndustry: [],
      industryList: [],
      list: {},
      checkListCategory: [ [] ],
      categoryList: [],
      navigateTo: '',
      loading: false,
      showIndex: null,
      groupIscheckList: [],
      btnLoading: false,
      btnTitle: '下一步'
    };
  },
  mounted () {
    this.companyName = this.$route.query.entityName;
    this.navigateTo = this.$route.query.navigateTo;
    const params = {
      memberId: getMemberId(),
      entityName: this.$route.query.entityName,
      navigateTo: this.$route.query.navigateTo
    };
    this.getInfo(params).then(() => {
      this.getRegion('000000', 'prov').then(() => {
        if (this.regionList[0].code) {
          this.getRegion(this.regionList[0].code, 'city').then(() => {
            if (this.regionList[1].code) {
              this.getRegion(this.regionList[1].code, 'area').then(() => {
                if (this.regionList[2].code) {
                  this.getRegion(this.regionList[2].code, 'street');
                }
              });
            }
          });
        }
      });
    });
  },
  methods: {
    /* 获取地域接口 */
    async getRegion (code, type) {
      const that = this;
      const { data: res } = await request({
        methods: 'GET',
        url: '/pcp/match/getChildAreaByCode?code=' + code
      });
      if (res.code !== 200) return that.$message.error('获取行政区域失败');
      if (type === 'prov') {
        that.regionList[0].options = res.data;
      } else if (type === 'city') {
        that.regionList[1].options = res.data;
      } else if (type === 'area') {
        that.regionList[2].options = res.data;
      } else {
        that.regionList[3].options = res.data;
      }
    },

    /* 查询企业基本信息 */
    async getInfo (params) {
      const { data: res } = await this.$http({
        method: 'POST',
        url: this.$baseURL + '/pcp/projectmatch/new/getBasicData',
        data: params,
        headers: { 'Content-Type': 'application/json' }
      });
      if (res.code !== 200) return this.$message.error('获取企业基本信息失败');
      this.list = res.data;
      res.data.category.forEach((v, i) => {
        this.checkListCategory[i] = [];
        if (v.isChecked) {
          this.groupIscheckList.push(i);
        }
      });
      this.industryList = res.data.category;
      this.regionList[0].code = res.data.provCode;
      this.regionList[0].name = res.data.provName;
      this.regionList[1].code = res.data.cityCode;
      this.regionList[1].name = res.data.cityName;
      this.regionList[2].code = res.data.areaCode;
      this.regionList[2].name = res.data.areaName;
      this.regionList[3].code = res.data.streetCode;
      this.regionList[3].name = res.data.streetName;
      this.enterpriseGroup = res.data.enterpriseGroup;
      res.data.enterpriseGroup.forEach(v => {
        if (v.isChecked) {
          this.scaleRadio = v.tagId;
        }
      });
      this.technologyRadio = res.data.technology.isChecked ? '1' : '0';
    },

    /* 选择行政区域 */
    changeRegion (index, code) {
      /* 选择省，则清空 市、区 、街道 */
      if (index < 1) {
        this.regionList[1].code = '';
        this.regionList[1].options = [];
        this.regionList[2].code = '';
        this.regionList[2].options = [];
        this.regionList[3].code = '';
        this.regionList[3].options = [];
        this.regionList[0].options.forEach(v => {
          if (code === v.code) {
            this.regionList[0].name = v.name;
          }
        });
        this.getRegion(code, 'city');
      } else if (index === 1) {
        this.regionList[2].code = '';
        this.regionList[2].options = [];
        this.regionList[3].code = '';
        this.regionList[3].options = [];
        this.regionList[1].options.forEach(v => {
          if (code === v.code) {
            this.regionList[1].name = v.name;
          }
        });
        this.getRegion(code, 'area');
      } else if (index === 2) {
        this.regionList[3].code = '';
        this.regionList[3].options = [];
        this.regionList[2].options.forEach(v => {
          if (code === v.code) {
            this.regionList[2].name = v.name;
          }
        });
        this.getRegion(code, 'street');
      } else {
        this.regionList[3].options.forEach(v => {
          if (code === v.code) {
            this.regionList[3].name = v.name;
          }
        });
      }
    },

    /* 选择所属行业 */
    changeIndustryGroup (index, isChecked) {
      this.showIndex = null;
      if (isChecked) {
        const res1 = [];
        res1.push(index);
        this.groupIscheckList = this.groupIscheckList.concat(res1);
        this.showIndex = index;
        let flag = false;
        flag = this.industryList.some(v => {
          return v.isFirst;
        });
        if (!flag) {
          this.industryList[this.groupIscheckList[0]].isFirst = true;
        }
        this.showIndex = null;
        this.$http({
          method: 'GET',
          url: this.$baseURL + '/pcp/projectmatch/new/getBigCategory?categoryId=' + this.industryList[index].tagId
        }).then((res) => {
          if (res.data.code !== 200) return this.$message.error('获取行业大类失败');
          this.industryList[index].bigCategory = res.data.data;
        });
      } else {
        if (this.groupIscheckList.length > 0) {
          let subscript;
          for (let j = 0; j < this.groupIscheckList.length; j++) {
            if (index === this.groupIscheckList[j]) {
              subscript = j;
            }
          }
          this.groupIscheckList.splice(subscript, 1);
        }
        this.checkListCategory[index] = [];
        this.industryList[index].bigCategory = [];
        this.industryList[index].isFirst = false;
        let flag = false;
        flag = this.industryList.some(v => {
          return v.isFirst;
        });
        if (!flag) {
          if (this.groupIscheckList.length > 0) {
            this.industryList[this.groupIscheckList[0]].isFirst = true;
          }
        }
      }
    },

    /* 鼠标移入 */
    mouseenter (index) {
      if (!this.industryList[index].isFirst) {
        this.showIndex = index;
      }
    },

    /* 鼠标移出 */
    mouseLeave (index) {
      this.showIndex = null;
    },

    /* 设为首选 */
    setPreferred (index) {
      if (!this.industryList[index].isChecked) {
        this.$http({
          method: 'GET',
          url: this.$baseURL + '/pcp/projectmatch/new/getBigCategory?categoryId=' + this.industryList[index].tagId
        }).then((res) => {
          if (res.data.code !== 200) return this.$message.error('获取行业大类失败');
          this.industryList[index].bigCategory = res.data.data;
        });
        this.industryList.forEach((v, i) => {
          if (i === index) {
            v.isFirst = true;
            v.isChecked = true;
          } else {
            v.isFirst = false;
          }
        });
        const res1 = [];
        res1.push(index);
        this.groupIscheckList = this.groupIscheckList.concat(res1);
        this.showIndex = null;
      } else {
        this.industryList.forEach((v, i) => {
          if (i === index) {
            v.isFirst = true;
            v.isChecked = true;
          } else {
            v.isFirst = false;
          }
        });
        this.showIndex = null;
      }
    },

    /* 选择行业大类 */

    handleCheckListCategory (index, i, v) {
      const flag = this.checkListCategory[index].some(item => {
        return item === v.tagId;
      });
      if (flag) {
        this.industryList[index].bigCategory[i].isChecked = true;
      } else {
        this.industryList[index].bigCategory[i].isChecked = false;
      }
    },

    /* 下一步 */
    submit () {
      this.btnLoading = true;
      this.btnTitle = '分析中';
      const provCode = this.regionList[0].code;
      const provName = this.regionList[0].name;
      const cityCode = this.regionList[1].code;
      const cityName = this.regionList[1].name;
      const areaCode = this.regionList[2].code;
      const areaName = this.regionList[2].name;
      const streetCode = this.regionList[3].code;
      const streetName = this.regionList[3].name;
      const scaleRadio = this.scaleRadio;

      let isFirstFlag = false;

      let isCheckedFlag = false;

      isFirstFlag = this.industryList.some(v => {
        return v.isFirst;
      });

      isCheckedFlag = this.industryList.some(v => {
        return v.isChecked;
      });

      if (scaleRadio === '0') {
        this.$message.error('企业规模为必选项');
        this.btnLoading = false;
        this.btnTitle = '下一步';
      } else if (!isFirstFlag) {
        this.$message.error('所属行业未设置首选');
        this.btnLoading = false;
        this.btnTitle = '下一步';
      } else if (!provCode || !isCheckedFlag) {
        this.$message.error('请填写或勾选必填项');
        this.btnLoading = false;
        this.btnTitle = '下一步';
      } else {
        this.loading = true;
        const params = {
          userId: getMemberId(),
          step: 1,
          entityName: this.companyName,
          basicData: {}
        };
        params.basicData.provCode = provCode;
        params.basicData.provName = provName;
        params.basicData.cityCode = cityCode;
        params.basicData.cityName = cityName;
        params.basicData.areaCode = areaCode;
        params.basicData.areaName = areaName;
        params.basicData.streetCode = streetCode;
        params.basicData.streetName = streetName;
        params.basicData.enterpriseGroup = this.enterpriseGroup;
        params.basicData.enterpriseGroup.forEach(v => {
          if (scaleRadio === v.tagId) {
            v.isChecked = true;
          } else {
            v.isChecked = false;
          }
        });
        params.basicData.technology = this.list.technology;
        params.basicData.technology.isChecked = this.technologyRadio === '1';
        params.basicData.category = this.industryList;
        // const str = JSON.stringify(params)
        // localStorage.setItem("parameter", str)
        localStorage.removeItem('localData');
        this.getList(params);
      }

      // if (scaleRadio == '0') return this.$message.error('企业规模为必选项');
      // if (!isFirstFlag) return this.$message.error('所属行业未设置首选');

      // if (!provCode || !isCheckedFlag) {
      //   this.$message.error('请填写或勾选必填项');
      // } else {
      //   this.loading = true
      //   let params = {
      //     userId: getMemberId(),
      //     step: 1,
      //     entityName: this.companyName,
      //     basicData: {}
      //   }
      //   params.basicData.provCode = provCode
      //   params.basicData.provName = provName
      //   params.basicData.cityCode = cityCode
      //   params.basicData.cityName = cityName
      //   params.basicData.areaCode = areaCode
      //   params.basicData.areaName = areaName
      //   params.basicData.streetCode = streetCode
      //   params.basicData.streetName = streetName
      //   params.basicData.enterpriseGroup = this.enterpriseGroup
      //   params.basicData.enterpriseGroup.forEach(v => {
      //     if (scaleRadio == v.tagId) {
      //       v.isChecked = true
      //     } else {
      //       v.isChecked = false
      //     }
      //   })
      //   params.basicData.technology = this.list.technology
      //   params.basicData.technology.isChecked = this.technologyRadio == '1' ? true : false
      //   params.basicData.category = this.industryList
      //   let str = JSON.stringify(params)
      //   this.loading = false
      //   localStorage.setItem("parameter", str)
      //   localStorage.removeItem("localData")
      //   this.$router.push({
      //     path: 'chooseCompany',
      //     query: { parameter: str }
      //   })

      // }
    },

    async getList (params) {
      const { data: res } = await this.$http({
        method: 'POST',
        url: this.$baseURL + '/pcp/projectmatch/new',
        data: params,
        headers: { 'Content-Type': 'application/json' }
      });
      this.btnLoading = false;
      if (res.code !== 200) {
        return this.$message.error('获取数据失败');
      } else {
        localStorage.setItem('paramsData', JSON.stringify(res.data));
        this.$router.push({
          path: 'chooseCompany',
          query: { entityName: res.data.entityName }
        });
        this.btnTitle = '下一步';
      }
    },

    /* 返回 */
    goBack () {
      if (localStorage.getItem('localData')) {
        this.$router.push({
          path: 'chooseCompany'
        });
      } else {
        localStorage.removeItem('localData');
        this.$router.push({
          path: 'searchCompany'
        });
      }
    },

    /* 参考标准 */
    handleStandard () {
      window.open('http://cdn.po-o.cn/2022/07/25/d33ec80cada94c40b010d9214650d377.pdf', '_blank');
    }

  }
};
</script>

<style lang="less" scoped>
.infoCompany {
  padding-top: 32px;

  .companyName {
    display: flex;
    border: 1px solid rgba(226, 237, 247, 100);
    height: 73px;
    border-radius: 5px;
    background-color: rgba(243, 248, 255, 67);
    color: rgba(16, 16, 16, 100);
    font-size: 18px;
    line-height: 73px;

    div:nth-child(1) {
      margin-left: 22px;
      margin-right: 11px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    div:nth-child(3) {
      margin-left: 27px;
    }

    div:nth-child(4) {
      color: #156ED0;
    }
  }

  .infoContent {
    border: 1px solid rgba(187, 187, 187, 50);
    padding: 30px 33px;
    margin-top: 20px;

    .region {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 35px;

      .region-select {
        display: flex;
        margin-top: 27px;
        margin-left: 40px;

        div {
          margin-right: 12px;
        }
      }
    }

    .companyScale {
      margin-top: 36px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 35px;

      .companyScale-title {
        display: flex;

        div:nth-child(2) {
          margin-left: 10px;
          color: rgba(255, 121, 2, 89);
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 16px;
            height: 16px;
            margin-right: 2px;
          }
        }
      }

      .companyScale-radioGroup {
        margin-top: 27px;
        margin-left: 40px;
      }
    }

    .technology {
      margin-top: 36px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 35px;

      .technology-radioGroup {
        margin-top: 27px;
        margin-left: 40px;
      }
    }

    .industry {
      margin-top: 36px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 35px;

      .industry-checkGroup {
        margin-top: 27px;
        margin-left: 40px;
        display: flex;
        flex-wrap: wrap;

        .industry-checkGroup-items {
          width: 33%;
          margin-bottom: 20px;
          height: 30px;
          display: flex;
        }
      }
    }

    .category {
      margin-top: 36px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 35px;

      .catgory-checkGroup {
        margin-top: 27px;
        margin-left: 40px;
      }
    }
  }
}

.mandatory {
  color: rgba(51, 51, 51, 100);
  font-size: 20px;
  font-family: SourceHanSansSC-medium;
}

.no-mandatory {
  color: rgba(51, 51, 51, 100);
  font-size: 20px;
  font-family: SourceHanSansSC-medium;
}

.mandatory:before {
  content: "* ";
  color: red;
}

.preferred {
  width: 40px;
  height: 24px;
  line-height: 24px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(48, 120, 240, 100);
  font-size: 12px;
  text-align: center;
  font-family: Arial;
  border: 1px solid rgba(48, 120, 240, 100);
  margin-left: 20px;
  display: inline-block;
}

.prepare {
  width: 40px;
  //flex:1;
  height: 24px;
  line-height: 24px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(154, 154, 154, 100);
  font-size: 12px;
  text-align: center;
  font-family: Arial;
  border: 1px solid rgba(190, 190, 190, 100);
  margin-left: 20px;
  display: inline-block;
}

.no-prepare {
  // flex:1;
  width: 40px;
  height: 24px;
  display: inline-block;
}

/deep/.el-input__inner {
  background-color: rgba(247, 247, 247, 100);
  color: #000;
}

/deep/ .el-radio {
  color: #999;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
  color: black
}

/deep/.el-checkbox {
  color: #999;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #000;
}

/deep/.el-checkbox__label {
  display: inline-grid;
  white-space: pre-line;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 20px;
}
</style>
